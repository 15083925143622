import React from 'react'
import PieChart from './PieChart'
import WorkTable from './WorkTable'
import { useForm } from '../../contexts/FormContext'
import { getAnswerByID, dateToYMD } from '../../utils'

//Material UI
import useStyles from '../../styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { useTranslation } from 'react-i18next'

const SummaryContent = () => {
	const { t: getTranslation } = useTranslation('time')

	const classes = useStyles()
	const { formData } = useForm()

	const answers = {
		1: getAnswerByID(1, 1, formData)?.value,
		2: getAnswerByID(2, 2, formData),
		3: getAnswerByID(3, 3, formData)?.value?.text.toLowerCase(),
		4: getAnswerByID(3, 4, formData)?.value,
		5: getAnswerByID(3, 5, formData)?.value,
		6:
			getAnswerByID(3, 6, formData).value.length === 0
				? getAnswerByID(4, 6, formData)
				: getAnswerByID(3, 6, formData), // 6 is in 3 and 4

		7: getAnswerByID(4, 7, formData),
		8: getAnswerByID(5, 8, formData)?.value?.text.toLowerCase(),
		9: getAnswerByID(5, 9, formData)?.value,
		10: getAnswerByID(5, 10, formData),
		11: getAnswerByID(5, 11, formData).value.filter(answer => answer.isChecked),
	}

	const optionalAnswers = {
		1: answers[6].value.find(answer => answer.id === 9)?.textAnswer.text,
		2: answers[10].value.find(answer => answer.id === 9)?.textAnswer.text,
	}

	// Format sentence for answer 11
	const answerEleven = () => {
		//words.slice(0, -1).join(', ') + ' and ' + words.splice(-1)
		let mappedAnswers = answers[11].map((answer, index) =>
			index >= 1 ? answer.text.toLowerCase() : answer.text
		)

		if (answers[11].length === 1) {
			return mappedAnswers
		} else if (answers[11].length === 2) {
			return mappedAnswers.join(' ja ')
		} else if (answers[11].length >= 3) {
			return (
				mappedAnswers.slice(0, -1).join(', ') +
				' ja ' +
				mappedAnswers.splice(-1)
			)
		}
	}

	// pdf_page class and last-pdf-page ID is used in downloadPDF function in Summary.js file
	// Giving a className='html2pdf__page-break' to an element will brake the page at that point in PDF

	const subtitles = [
		getTranslation('subtitles.id_1'),
		getTranslation('subtitles.id_2'),
	]

	const possibleOutcomes = [
		{
			id: 1,
			question: 2,
			condition: 'YES',
			content: function Content(key) {
				return (
					<Box key={key} my={2} className='pdf_page'>
						<Box>
							<Typography variant='h6' gutterBottom>
								{subtitles[0]}
							</Typography>

							<Typography>
								{getTranslation('possible_outcomes.yes.id_1')}
							</Typography>
						</Box>
						<Box my={3}>
							<Typography align='center' variant='h5'>
								{/* Aikasi nykyhetkellä */}
								{getTranslation('possible_outcomes.id_2')}
							</Typography>
							<PieChart answer={answers[1]} />
						</Box>
						<Box my={3}>
							<Typography align='center' variant='h5'>
								{/* Aikasi tulevaisuudessa */}
								{getTranslation('possible_outcomes.id_3')}
							</Typography>
							<PieChart answer={answers[5]} />
						</Box>
						<Box my={3}>
							<Typography>
								{getTranslation('possible_outcomes.yes.id_4')}
							</Typography>
						</Box>
						<Box className='html2pdf__page-break'></Box>
						<Box mt={3} mb={5}>
							<Typography>
								{/* Olet vastannut, että haluat muutosta */}
								{getTranslation('possible_outcomes.yes.id_5')}
								{typeof answers[4] === 'boolean'
									? ', ' + getTranslation('possible_outcomes.id_6')
									: ` ${getTranslation('possible_outcomes.id_7')} ${dateToYMD(
											new Date(answers[4])
									  )}`}
								. {getTranslation('possible_outcomes.yes.id_8')}
							</Typography>
						</Box>
						<Box className='html2pdf__page-break'></Box>
						<Divider data-html2canvas-ignore='true' />
					</Box>
				)
			},
		},
		{
			id: 2,
			question: 2,
			condition: 'MAYBE',
			content: function Content(key) {
				return (
					<Box key={key} my={3}>
						<Box className='pdf_page'>
							<Box>
								<Typography variant='h6' gutterBottom>
									{subtitles[0]}
								</Typography>
								<Typography>
									{getTranslation('possible_outcomes.maybe.id_1')}
								</Typography>
							</Box>

							<Box my={3}>
								<Grid item xs={12}>
									<Typography align='center' variant='h5'>
										{/* Aikasi nykyhetkellä */}
										{getTranslation('possible_outcomes.id_2')}
									</Typography>
									<PieChart answer={answers[1]} />
								</Grid>
							</Box>
							<Box my={3}>
								<Grid item xs={12}>
									<Typography align='center' variant='h5'>
										{/* Aikasi tulevaisuudessa */}
										{getTranslation('possible_outcomes.id_3')}
									</Typography>
									<PieChart answer={answers[5]} />
								</Grid>
							</Box>

							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes.maybe.id_4')}
								</Typography>
							</Box>
						</Box>

						<Box className='html2pdf__page-break'></Box>

						<Box className='pdf_page'>
							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes.maybe.id_5')} {answers[3]}{' '}
									{typeof answers[4] === 'boolean'
										? ', ' + getTranslation('possible_outcomes.id_6')
										: ` ${getTranslation('possible_outcomes.id_7')} ${dateToYMD(
												new Date(answers[4])
										  )}`}
									. {getTranslation('possible_outcomes.maybe.id_8')}
								</Typography>
							</Box>
						</Box>

						<Box className='html2pdf__page-break'></Box>

						<Box my={3}>
							<Divider data-html2canvas-ignore='true' />
						</Box>
					</Box>
				)
			},
		},
		{
			id: 3,
			question: 2,
			condition: 'NO',
			content: function Content(key) {
				return (
					<Box key={key} my={3} className='pdf_page'>
						<Box>
							<Typography variant='h6' gutterBottom>
								{subtitles[0]}
							</Typography>

							<Typography>
								{getTranslation('possible_outcomes.no.id_1')}
							</Typography>
						</Box>

						<Box my={3}>
							<Grid container direction='row' justify='space-around'>
								<Grid item xs={12} sm={8} md={7}>
									<Typography align='center' variant='h5'>
										{getTranslation('possible_outcomes.no.id_4')}
									</Typography>
									<PieChart answer={answers[1]} />
								</Grid>
							</Grid>
						</Box>

						<Box>
							<Typography>
								{getTranslation('possible_outcomes.no.id_5')}
							</Typography>
						</Box>

						<Box className='html2pdf__page-break'></Box>

						<Box my={3}>
							<Divider data-html2canvas-ignore='true' />
						</Box>
					</Box>
				)
			},
		},
		// Possible outcomes 2
		{
			id: 4,
			question: 7,
			condition: 'YES',
			content: function Content(key) {
				return (
					<Box key={key} mt={2}>
						<Box className='pdf_page'>
							<Box>
								<Typography variant='h6' gutterBottom>
									{' '}
									{subtitles[1]}
								</Typography>
								<Typography>
									{getTranslation('possible_outcomes_2.yes.id_1')}
								</Typography>
							</Box>

							<Box my={3}>
								<WorkTable present={answers[6]} future={answers[10]} />
							</Box>
							<Box>
								<Typography>
									{optionalAnswers[1]
										? getTranslation('possible_outcomes_2.id_2') +
										  ': ' +
										  optionalAnswers[1]
										: null}
								</Typography>
								<Typography>
									{optionalAnswers[2]
										? getTranslation('possible_outcomes_2.id_3') +
										  ': ' +
										  optionalAnswers[2]
										: null}
								</Typography>
							</Box>
						</Box>

						<Box className='html2pdf__page-break'></Box>

						<Box id='last-pdf-page' className='pdf_page'>
							<Box mt={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.yes.id_4')}
								</Typography>
							</Box>

							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.yes.id_5')}
									{answers[8]}
									{typeof answers[9] === 'boolean'
										? ', ' + getTranslation('possible_outcomes.id_6')
										: ` ${getTranslation('possible_outcomes.id_7')} ${dateToYMD(
												new Date(answers[9])
										  )}`}
									. {getTranslation('possible_outcomes_2.yes.id_8')}
								</Typography>
							</Box>

							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.yes.id_9')}{' '}
									<a
										href='https://www.entrefox.fi/arjen-ajanhallinta/'
										target='blank'
										className={classes.linkTag}
									>
										https://www.entrefox.fi/arjen-ajanhallinta/
									</a>
									. {getTranslation('possible_outcomes_2.id_10')}{' '}
									<a
										href='https://todoist.com/app/today'
										target='blank'
										className={classes.linkTag}
									>
										https://todoist.com/app/today
									</a>{' '}
									{getTranslation('possible_outcomes_2.id_11')}{' '}
									<a
										href='https://www.kitetech.co/keepmynotes'
										target='blank'
										className={classes.linkTag}
									>
										https://www.kitetech.co/keepmynotes
									</a>
									. {getTranslation('possible_outcomes_2.yes.id_12')}
								</Typography>
							</Box>

							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.id_13')}:{' '}
									<Typography
										component='span'
										color='primary'
										style={{ fontWeight: 600 }}
									>
										{answerEleven()}.
									</Typography>{' '}
								</Typography>
							</Box>
							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.yes.id_14')}
								</Typography>
							</Box>
						</Box>
						<Box my={4}>
							<Divider data-html2canvas-ignore='true' />
						</Box>
					</Box>
				)
			},
		},
		{
			id: 5,
			question: 7,
			condition: 'MAYBE',
			content: function Content(key) {
				return (
					<Box key={key} my={3}>
						<Box className='pdf_page'>
							<Box>
								<Typography variant='h6'>{subtitles[1]}</Typography>
								<Typography>
									{getTranslation('possible_outcomes_2.maybe.id_1')}
								</Typography>
							</Box>

							<Box my={3}>
								<WorkTable present={answers[6]} future={answers[10]} />
							</Box>
							<Box>
								<Typography>
									{optionalAnswers[1]
										? getTranslation('possible_outcomes_2.id_2') +
										  ': ' +
										  optionalAnswers[1]
										: null}
								</Typography>
								<Typography>
									{optionalAnswers[2]
										? getTranslation('possible_outcomes_2.id_3') +
										  ': ' +
										  optionalAnswers[2]
										: null}
								</Typography>
							</Box>
						</Box>

						<Box className='html2pdf__page-break'></Box>

						<Box className='pdf_page' id='last-pdf-page'>
							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.maybe.id_4')}
								</Typography>
							</Box>

							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.maybe.id_5')}
									{answers[8]}{' '}
									{typeof answers[9] === 'boolean'
										? ', ' + getTranslation('possible_outcomes.id_6')
										: ` ${getTranslation('possible_outcomes.id_7')} ${dateToYMD(
												new Date(answers[4])
										  )}`}
									. {getTranslation('possible_outcomes_2.maybe.id_8')}
								</Typography>
							</Box>

							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.maybe.id_9')}:{' '}
									<a
										href='https://www.entrefox.fi/arjen-ajanhallinta/'
										target='blank'
										className={classes.linkTag}
									>
										https://www.entrefox.fi/arjen-ajanhallinta/
									</a>
									. {getTranslation('possible_outcomes_2.id_10')}{' '}
									<a
										href='https://todoist.com/app/today'
										target='blank'
										className={classes.linkTag}
									>
										https://todoist.com/app/today
									</a>{' '}
									{getTranslation('possible_outcomes_2.id_11')}{' '}
									<a
										href='https://www.kitetech.co/keepmynotes'
										target='blank'
										className={classes.linkTag}
									>
										https://www.kitetech.co/keepmynotes .
									</a>{' '}
									{getTranslation('possible_outcomes_2.maybe.id_12')}
								</Typography>
							</Box>

							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.id_13')}:{' '}
									<Typography
										component='span'
										color='primary'
										style={{ fontWeight: 600 }}
									>
										{answerEleven()}
									</Typography>
									. {getTranslation('possible_outcomes_2.maybe.id_14')}
								</Typography>
							</Box>
						</Box>
						<Box my={3}>
							<Divider data-html2canvas-ignore='true' />
						</Box>
					</Box>
				)
			},
		},
		{
			id: 6,
			question: 7,
			condition: 'NO',
			content: function Content(key) {
				return (
					<Box key={key} my={3}>
						<Box className='pdf_page'>
							<Typography variant='h6'> {subtitles[1]}</Typography>
							<Typography>
								{getTranslation('possible_outcomes_2.no.id_1')}
							</Typography>
							<Box my={3}>
								<WorkTable present={answers[6]} />
							</Box>
							<Box>
								<Typography>
									{optionalAnswers[1]
										? getTranslation('possible_outcomes_2.id_2') +
										  ': ' +
										  optionalAnswers[1]
										: null}
								</Typography>
							</Box>
						</Box>

						<Box className='html2pdf__page-break'></Box>

						<Box className='pdf_page' id='last-pdf-page'>
							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.no.id_9')}
								</Typography>
							</Box>

							<Box my={2}>
								<Typography>
									{getTranslation('possible_outcomes_2.id_13')}:{' '}
									<Typography
										component='span'
										color='primary'
										style={{ fontWeight: 600 }}
									>
										{answerEleven()}.
									</Typography>
									{''}
									{getTranslation('possible_outcomes_2.no.id_14')}
								</Typography>
							</Box>
						</Box>

						<Box my={3}>
							<Divider data-html2canvas-ignore='true' />
						</Box>
					</Box>
				)
			},
		},
	]

	// Render outcomes depending on what the user answered on questions 2 and 7
	const outcomesToRender = possibleOutcomes.filter(outcome => {
		if (
			outcome.question === answers[2].id &&
			outcome.condition === answers[2]?.value.condition
		) {
			return outcome
		}
		if (
			outcome.question === answers[7].id &&
			outcome.condition === answers[7]?.value.condition
		) {
			return outcome
		}
		return null
	})
	return outcomesToRender.map(outcome => outcome.content(outcome.id))
}

export default SummaryContent
