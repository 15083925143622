import React from 'react'
import PropTypes from 'prop-types'
// import { Pie as PieCanvas } from 'react-chartjs-2'
// import { PieChart as Pie } from 'react-minimal-pie-chart'

// Material UI
import Box from '@material-ui/core/Box'

import { PieChart as RechartsPieChart, Pie, Cell, Legend } from 'recharts'

// There are 2 charts, becuase converting svg to pdf is difficult and canvas chart cant auto display label value.
const PieChart = ({ answer }) => {
	if (answer) {
		// Put properties to a new array
		let dataArray = []
		const COLORS = [
			'rgba(255, 99, 132, 1)',
			'rgba(54, 162, 235, 1)',
			'rgba(255, 206, 86, 1)',
		]
		answer.map((slider, index) => {
			dataArray.push({
				name: slider.text,
				value: slider.range,
				color: COLORS[index],
			})
		})

		// Labels
		const RADIAN = Math.PI / 180
		const renderCustomizedLabel = ({
			cx,
			cy,
			midAngle,
			innerRadius,
			outerRadius,
			value,
		}) => {
			const radius = innerRadius + (outerRadius - innerRadius) * 0.5
			const x = cx + radius * Math.cos(-midAngle * RADIAN)
			const y = cy + radius * Math.sin(-midAngle * RADIAN)

			return (
				<text
					x={x}
					y={y}
					fill='black'
					textAnchor={x > cx ? 'start' : 'end'}
					dominantBaseline='central'
					style={{
						fontSize: '2rem',
						fontColor: 'FFFFFA',
						fontFamily: 'Roboto',
					}}
				>
					{value}
				</text>
			)
		}

		const renderLegendText = value => {
			return (
				<span
					style={{
						color: 'black',
						fontFamily: 'Roboto',
						display: 'inline-flex',
						margin: 3
					}}
				>
					{value}
				</span>
			)
		}

		return (
			<Box
				id='pie-container'
				align='center'
				mx={'auto'}
				style={{ maxWidth: '400px' }}
			>
				{/* Rechart */}
				<RechartsPieChart width={400} height={400}>
					<Legend verticalAlign='top' formatter={renderLegendText} />
					<Pie
						isAnimationActive={false}
						data={dataArray}
						cx='50%'
						cy='50%'
						labelLine={false}
						label={renderCustomizedLabel}
						outerRadius={150}
						fill='#8884d8'
						dataKey='value'
					>
						{dataArray.map((entry, index) => (
							<Cell
								key={`cell-${index}`}
								fill={COLORS[index % COLORS.length]}
							/>
						))}
					</Pie>
				</RechartsPieChart>
			</Box>
		)
	}
	return null
}

PieChart.propTypes = {
	answer: PropTypes.array,
}

export default PieChart
